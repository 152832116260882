import React, { useState } from 'react'
import Bounce from 'react-reveal/Bounce'
import { Link } from 'gatsby'
import { useIntl, changeLocale } from 'gatsby-plugin-react-intl'
import { Language } from '../helpers/language.model'
import TeamTile from './TeamTile'
import lukasz from '../img/lukasz.jpg'
import aneta from '../img/aneta.jpg'
import bgPattern from '../img/dot-grid.png'

function Team(props) {
  const { isPl } = props
  const [language, setLanguage] = useState(Language.pl)
  const intl = useIntl()

  const anetaContentBack = (
    <div>
      <p className="mb-2">{intl.messages.anetaTileP1}</p>
      <p>{intl.messages.anetaTileP2}</p>
      <strong>{`${intl.messages.lifeRecords}:`}</strong>
      <ul>
        <li>400m - 52.68</li>
        <li>800m - 1.59.93</li>
      </ul>
      <div className="has-text-centered" style={{ marginTop: '10px' }}>
        <h4 className="is-size-4">{`${intl.messages.mobilePhone}: +48 501 873 668`}</h4>
        <h4 className="is-size-4">Email: aneta.lemiesz@lemieszteam.pl</h4>
      </div>
    </div>
  )
  const lukaszContentBack = (
    <div>
      <p>{intl.messages.lukaszTileP1}</p>
      <p>{intl.messages.lukaszTileP2}</p>
      <strong>{`${intl.messages.lifeRecords}:`}</strong>
      <ul>
        <li>{intl.messages.longJump}– 7.10m</li>
        <li>{intl.messages.tripleJump}– 13.77m</li>
      </ul>
      <div className="has-text-centered" style={{ marginTop: '10px' }}>
        <h4 className="is-size-4">{`${intl.messages.mobilePhone}: +48 600 39 00 39`}</h4>
        <h4 className="is-size-4">Email: lukasz.sobora@lemieszteam.pl</h4>
      </div>
    </div>
  )

  return (
    <section
      id="team"
      className="section"
      style={{
        backgroundColor: '#E0EDFC',
        backgroundImage: `url(${bgPattern})`,
      }}
    >
      <div className="container">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
          }}
        >
          <Bounce left>
            <h1 className="title gradient-box">{intl.messages.team}</h1>
          </Bounce>
          <Bounce left>
            <div>
              <h2
                className="title"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Link
                  className={`clickedSpan ${isPl ? 'rainbow-underline' : ''}`}
                  to="/"
                  // onClick={() => changeLocale("pl")}
                >
                  PL
                </Link>{' '}
                |{' '}
                <Link
                  className={`clickedSpan ${!isPl ? 'rainbow-underline' : ''}`}
                  to="/en"
                  // onClick={() => changeLocale("en")}
                >
                  ENG
                </Link>
              </h2>
            </div>
          </Bounce>
        </div>
        <div className="columns">
          <div className="column col-4">
            <Bounce left>
              <TeamTile
                personImg={aneta}
                name="Aneta Lemiesz"
                backContent={anetaContentBack}
                detailsPageName="aboutAneta"
              />
            </Bounce>
          </div>
          <div className="column col-4">
            <Bounce right>
              <TeamTile
                personImg={lukasz}
                name="Łukasz Sobora"
                backContent={lukaszContentBack}
                detailsPageName="aboutLukasz"
              />
            </Bounce>
          </div>
        </div>
        <div
          className="columns"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '1200px',
          }}
        >
          <div className="column is-half"></div>
          {/* <div className="column"></div> */}
          <div className="column"></div>
        </div>
      </div>
    </section>
  )
}

export default Team
