import React from 'react'
import { useIntl, Link } from 'gatsby-plugin-react-intl'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import inboxIllustraton from '../img/inbox-messages_sent.svg'
import '../styles/styles.scss'

function Newsletter() {
  const intl = useIntl()
  const [firstname, setFirstname] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [isConfirmed, setConfirmed] = React.useState(false)

  const handleFirstnameChange = event => {
    const { value } = event.target
    setFirstname(value)
  }

  const handleEmailChange = event => {
    const { value } = event.target
    setEmail(value)
  }

  const handleCheckboxChange = event => {
    // const { checked } = event.target
    setConfirmed(!isConfirmed)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (!isConfirmed) {
      toast.error(intl.messages.markAgreement)
      return
    }

    //TODO: add gender to the form and the response object
    const data = {
      name: firstname,
      email,
    }

    const url = `https://${process.env.GATSBY_baseUrl}/wp-json/newsletter/v1/subscribe`

    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // redirect: 'follow', // manual, *follow, error
      // referrer: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })

    if (response.status === 200) {
      toast.success(intl.messages.newsletterSuccess)
      setFirstname('')
      setEmail('')
      setConfirmed(false)
    } else {
      toast.error(intl.messages.errorEmailAlreadyExists)
    }
  }

  return (
    <section className="section">
      <div className="container">
        <div
          className="columns"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '1200px',
          }}
        >
          <div className="column is-half">
            <img src={inboxIllustraton} alt="" />
          </div>
          {/* <div className="column"></div> */}
          <div className="column">
            <h1 className="title">Newsletter</h1>
            <h2 className="subtitle">{intl.messages.newsletterInduce}</h2>
            <form method="post" onSubmit={handleSubmit}>
              <div className="field">
                <label className="label is-medium" htmlFor="firstname">
                  {intl.messages.firstname}
                </label>
                <div className="control">
                  <input
                    className="input is-medium"
                    type="text"
                    placeholder="Jan"
                    value={firstname}
                    id="firstname"
                    name="firstname"
                    onChange={handleFirstnameChange}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label is-medium" htmlFor="email">
                  {intl.messages.email}
                </label>
                <div className="control">
                  <input
                    className="input is-medium"
                    type="email"
                    placeholder="email@email.com"
                    id="email"
                    value={email}
                    name="email"
                    onChange={handleEmailChange}
                    required
                  />
                </div>
                {/* <p className="help is-danger">Niepoprawny format</p> */}
              </div>

              <div className="field">
                <div className="control">
                  <div className="page__toggle">
                    <label className="checkbox is-medium toggle">
                      <input
                        type="checkbox"
                        className="toggle__input"
                        id="isAgree"
                        name="isAgree"
                        onChange={handleCheckboxChange}
                        // required
                      />
                      <span className="toggle__label">
                        <span className="toggle__text">
                          {intl.messages.privatePolicyLabel + ' '}
                          <a
                            href={intl.messages.regulationsUrl}
                            target="_blank"
                          >
                            {intl.messages.processignOfPersonalData}
                          </a>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <button type="submit" className="button-shaking">
                  {intl.messages.subscribe}
                </button>
              </div>
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
