import React from 'react'
import Slider from 'react-animated-slider'
import { useIntl, Link } from 'gatsby-plugin-react-intl'
import 'react-animated-slider/build/horizontal.css'
import './all.sass'
import './button.scss'
import './styles.scss'
import './slider-animations.scss'
import slide1 from '../img/slide1.jpg'

function AnimatedSlider({ setModalActive }) {
  const intl = useIntl()

  const toggleModalActive = () => {
    setModalActive(true)
  }

  const content = [
    {
      title: intl.messages.slideFirstTitle,
      description: '',
      button: intl.messages.watchVideo,
      image: slide1,
    },
  ]

  return (
    <Slider
      autoplay={5000}
      infinite={true}
      className="slider-wrapper is-fullwidth skewed"
    >
      {content.map((item, index) => (
        <div
          key={index}
          className="slider-content"
          style={{
            background: `url('${item.image}') no-repeat center top`,
            borderRadius: '15px 15px 0 0',
          }}
        >
          <div className="inner">
            <Link to="/kontakt">
              <h1>{item.title}</h1>
            </Link>
            <p>{item.description}</p>
            <button
              type="button"
              className="btn draw-border"
              onClick={toggleModalActive}
            >
              <h6>{item.button}</h6>
            </button>
          </div>
          {/* <section>
                        <img src={item.userProfile} alt={item.user} />
                        <span>
                            Posted by <strong>{item.user}</strong>
                        </span>
                    </section> */}
        </div>
      ))}
    </Slider>
  )
}

export default AnimatedSlider
