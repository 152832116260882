import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import YouTube from '@u-wave/react-youtube'
import Zoom from 'react-reveal/Zoom'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import { Hero } from '../components/Hero'
import Newsletter from '../components/Newsletter'
import Team from '../components/Team'
import Modal from '../components/Modal'
import bgPattern from '../img/dot-grid.png'
import AnimatedSlider from '../components/AnimatedSlider'

import { injectIntl, Link, FormattedMessage } from 'gatsby-plugin-react-intl'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalActive: false,
      isVideoPaused: true,
    }
    this.setModalActive = isActive => this.setState({ isModalActive: isActive })
    this.pauseVideo = () => {
      this.setState({ isVideoPaused: true })
    }

    this.closeModal = () => {
      this.setState({ isModalActive: false })
      this.pauseVideo()
    }

    this.handlePlayerPause = () => {
      this.setState({ isVideoPaused: true })
    }

    this.handlePlayerPlay = () => {
      this.setState({ isVideoPaused: false })
    }
  }

  render() {
    const { data, pageContext, intl, location } = this.props
    const isPl =
      location.pathname === '/' || location.pathname.startsWith('/pl')
    const latestPosts = isPl ? data.latestPostsPL : data.latestPostsEN
    const { edges: posts } = latestPosts
    const opts = {
      height: '600px',
      width: '900px',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    }

    return (
      <Layout>
        {/* <Hero setModalActive={this.setModalActive} /> */}
        <AnimatedSlider setModalActive={this.setModalActive} />
        <Team isPl={isPl} />
        <section className="section-bubble3">
          <Zoom top>
            {/* "Latest posts" */}
            <PostList posts={posts} title={intl.messages.latestPosts} />
          </Zoom>
        </section>
        <section className="section-bubble4">
          <Zoom top>
            <Newsletter />
          </Zoom>
        </section>

        <Modal isActive={this.state.isModalActive} closeModal={this.closeModal}>
          <div className="yt-modal">
            <YouTube
              video="XN8glc5cS5k"
              paused={this.state.isVideoPaused}
              onPause={this.handlePlayerPause}
              onPlaying={this.handlePlayerPlay}
            />
          </div>
        </Modal>
      </Layout>
    )
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo()
    console.log(event.target)
  }

  _onStateChange = event => {
    return -1
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWpPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export default injectIntl(IndexPage)

export const pageQuery = graphql`
  query {
    latestPostsPL: allWpPost(
      sort: { fields: date, order: DESC }
      limit: 2
      filter: { language: { language: { eq: "pl" } } }
    ) {
      edges {
        node {
          id
          title
          excerpt
          author {
            node {
              name
              slug
              avatar {
                url
              }
            }
          }
          date(formatString: "DD-MM-YYYY")
          slug
          language {
            language
          }
          featuredImage {
            node {
              sourceUrl
              sizes
              title
              altText
            }
          }
        }
      }
    }
    latestPostsEN: allWpPost(
      sort: { fields: date, order: DESC }
      limit: 2
      filter: { language: { language: { eq: "en" } } }
    ) {
      edges {
        node {
          id
          title
          excerpt
          author {
            node {
              name
              slug
              avatar {
                url
              }
            }
          }
          date(formatString: "DD-MM-YYYY")
          slug
          language {
            language
          }
          featuredImage {
            node {
              sourceUrl
              sizes
              title
              altText
            }
          }
        }
      }
    }
  }
`
