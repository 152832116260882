import React from 'react'
import { Link } from 'gatsby-plugin-react-intl'

import './teamTile.sass'
import ReactCardFlip from 'react-card-flip'

function TeamTile(props) {
  const { personImg, name, backContent, detailsPageName } = props

  const [isFlipped, setFlip] = React.useState(false)

  const toggleCard = () => {
    // e.preventDefault();
    setFlip(!isFlipped)
  }

  return (
    <div onClick={toggleCard}>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <div className="team-tile" key="front">
          <div
            className="avatar clip"
            style={{
              backgroundImage: `url(${personImg})`,
            }}
          ></div>
          <div className="team-tile--content" style={{ paddingTop: '5%' }}>
            <Link
              to={`/${detailsPageName}`}
              style={{ fontSize: '2.5em' }}
              className="rainbow-underline large-hover pointer"
              // onClick={toggleCard}
            >
              {name}
            </Link>
          </div>
        </div>

        <div className="team-tile" key="back">
          <div className="team-tile--content">
            <Link
              to={`/${detailsPageName}`}
              style={{ fontSize: '2.5em' }}
              className="rainbow-underline large-hover pointer"
              // onClick={toggleCard}
            >
              {name}
            </Link>
            <div style={{ marginTop: '15px' }}>{backContent}</div>
          </div>
        </div>
      </ReactCardFlip>
    </div>
  )
}

export default TeamTile
